<template>
  <div>
    <div id="form_login">
      <div class="mb-3">
        <label for="login-username" class="form-label">Username</label>
        <input type="text" class="form-control" id="login-username" v-model="username" />
      </div>
      <div class="mb-3">
        <label for="login-password" class="form-label">Passwort</label>
        <input type="password" class="form-control" id="login-password" v-model="password" v-on:keyup.enter="login" />
      </div>
      <button class="btn btn-success btn-sm" @click="login">Anmelden</button>

    </div>
  </div>
</template>

<script>
import sha512 from 'js-sha512'

export default {
  name: 'LoginForm',
  data: function () {
    return {
      username: null,
      password: null
    }
  },
  methods: {
    login: function () {
      var self = this;
      fetch(this.$parent.Endpoint + '/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: this.username, password: sha512(this.password) })
      })
        .then(res => res.json())
        .then(data => {

          self.$parent.user = data
          self.$cookies.set("token", data.token)
          self.$parent.loadData()
          self.$parent.showLogin = false
        })
        .catch(rejected => {
          self.$parent.showError("Login fehlgeschlagen!")
          console.log(rejected);
        });
    },
  }
}
</script>

<style scoped>
.card-header {
  font-weight: bold !important;
}

.card-body {
  text-align: center !important;
}

#form_login {
  left: 50%;
  top: 50%;
  width: 300px;
  position: absolute;
  transform: translate(-50%, -50%);
  
  padding: 20px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.16);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /*backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);*/
  border: 1px solid rgba(255, 255, 255, 0.12);
  
}
label
{
  font-weight: bold;
}

#form_login::before {
  content: "";
}

input,
input:focus,
input:active
{
  padding: 10px 20px;
  border-color: transparent;
  outline: none;
  font-size: 15px;
  background: transparent;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
  text-shadow: 10px solid black;
  box-shadow:inset 0px 0px 30px 5px rgba(0,0,0,0.31);
}



</style>
