<template>
  <!--<img alt="Vue logo" src="./assets/logo.png">

  <div class="alert alert-primary" role="alert">
    A simple primary alert—check it out!
  </div>

  <HelloWorld msg="Welcome to Your Vue.js App" />-->
  <div>
    <!--<div class="alert alert-danger" role="alert" id="globalError" v-if="globalError">
      {{globalError}}
    </div>-->
    <div class="toast-container position-fixed top-0 end-0 p-3">
      <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">Fehler</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{ globalError }}
        </div>
      </div>
    </div>
    <div id="loggedInContent" v-if="user">
      <nav class="navbar navbar-expand-lg" ref="nav">
        <div class="container-fluid">
          <a class="navbar-brand" href="#">Lagerverwaltung</a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
            aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarScroll">
            <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 100px;">
              <li class="nav-item">
                <a :class="'nav-link' + (site == 'home' ? ' active' : '')" aria-current="page" href="#"
                  @click="site = 'home'">Home</a>
              </li>
              <li class="nav-item">
                <a :class="'nav-link' + (site == 'storage' ? ' active' : '')" aria-current="page" href="#"
                  @click="site = 'storage'">Lager</a>
              </li>
              <li class="nav-item">
                <a :class="'nav-link' + (site == 'bank' ? ' active' : '')" aria-current="page" href="#"
                  @click="site = 'bank'">Kasse</a>
              </li>
              <li class="nav-item">
                <a :class="'nav-link' + (site == 'member' ? ' active' : '')" aria-current="page" href="#"
                  @click="site = 'member'">Mitglieder</a>
              </li>
              <li class="nav-item">
                <a :class="'nav-link' + (site == 'items' ? ' active' : '')" aria-current="page" href="#"
                  @click="site = 'items'">Items</a>
              </li>
              <!--<li class="nav-item">
                <a :class="'nav-link' + (site == 'ranks' ? ' active' : '')" aria-current="page" href="#"
                  @click="site = 'ranks'">Ränge</a>
              </li>-->

              <!--
            <li class="nav-item">
              <a class="nav-link" href="#">Link</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Link
              </a>
              <ul class="dropdown-menu">
                <li><a class="dropdown-item" href="#">Action</a></li>
                <li><a class="dropdown-item" href="#">Another action</a></li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-link disabled" aria-disabled="true">Link</a>
            </li>-->
            </ul>
          </div>
        </div>
      </nav>
      <div class="container content">
        <HomeSite v-if="site == 'home'" />
        <MemberList v-if="site == 'member'" />
        <ItemList v-if="site == 'items'" />
        <StorageList v-if="site == 'storage'" />
        <BankList v-if="site == 'bank'" />


      </div>
    </div>
    <LoginForm v-if="showLogin" />
  </div>
</template>

<script>
import moment from 'moment';
import MemberList from './components/MemberList.vue'
import ItemList from './components/ItemList.vue'
import HomeSite from './components/Home.vue'
import StorageList from './components/StorageList.vue'
import BankList from './components/BankList.vue'
import LoginForm from './components/LoginForm.vue'
import { Toast } from 'bootstrap/js/index.esm';

export default {
  name: 'App',
  components: {
    //HelloWorld
    MemberList,
    HomeSite,
    ItemList,
    StorageList,
    BankList,
    LoginForm
  },
  data: function () {
    return {
      site: "home",
      items: [],
      members: [],
      storage: [],
      ranks: [],
      bank: [],
      bankSum: 0,
      user: null,
      showLogin: false,
      globalError: null,
      storageSum: []
    }
  },
  computed: {
    Endpoint: function () {
      if (window.location.href.includes("http://localhost")) {
        return "http://127.0.0.1:5000"
      }
      else {
        let splittedHref = window.location.href.split('/')
        splittedHref.pop()
        return splittedHref.join('/') + '/api'
      }
    }
  },
  mounted: async function () {
    var token = this.$cookies.get('token')
    var self = this

    if (token != null) {
      fetch(this.Endpoint + "/LoginCheck", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
      })
        .then(res => res.json())
        .then(data => {
          self.user = { token: token, member: data };
          this.showLogin = false
          self.loadData()
        })
        .catch(rejected => {
          console.log(rejected);
          this.showLogin = true
        });
    }
    else {
      this.showLogin = true
    }
  },
  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
    Tausender(x) {
      x = x.toString();
      var pattern = /(-?\d+)(\d{3})/;
      while (pattern.test(x))
        x = x.replace(pattern, "$1.$2");
      return x;
    },
    showError: function (errortext) {
      this.globalError = errortext;
      //this.globalError = errortext;
      //setTimeout(this.globalError = null, 5000);
      /*const toastBootstrap = */Toast.getOrCreateInstance(document.getElementById('liveToast')).show()

    },
    refreshMembers: function()
    {
      var self = this;
      fetch(this.Endpoint + "/GetMembers", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.members = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
    },
    refreshItems: function()
    {
      var self = this;
      fetch(this.Endpoint + "/GetItems", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.items = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
    },
    refreshBank: function()
    {
      var self = this;
      fetch(this.Endpoint + "/GetBank", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.bank = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
        fetch(this.Endpoint + "/GetBankSum", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.bankSum = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
    },
    refreshStorage: function()
    {
      var self = this;
      fetch(this.Endpoint + "/GetStorage", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.storage = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
        fetch(this.Endpoint + "/GetStorageSum", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.storageSum = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
    },    
    loadData: function () {
      var self = this;
      fetch(this.Endpoint + "/GetItems", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.items = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
      fetch(this.Endpoint + "/GetStorage", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.storage = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
      fetch(this.Endpoint + "/GetMembers", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.members = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
      fetch(this.Endpoint + "/GetRanks", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.ranks = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
        fetch(this.Endpoint + "/GetBank", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.bank = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
        fetch(this.Endpoint + "/GetBankSum", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.bankSum = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
        fetch(this.Endpoint + "/GetStorageSum", {
        headers: { Authorization: 'Bearer ' + this.user.token }
      })
        .then(res => res.json())
        .then(data => {
          self.storageSum = data;
        })
        .catch(rejected => {
          console.log(rejected);
        });
    },
    top: function () {
      window.scrollTo(0, 0);
    }
  }
}
</script>

<style>
html {
  color: #c5d1d3;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: all 10000000s;
}

body {
  height: 100% !important;
  width: 100% !important;
  background-image: url('assets/bg2.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  margin-top: 0px;
  font-size: 15px;
}

nav {
  background: rgba(255, 255, 255, 0.16);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.content {
  margin-top: 30px;
}

#globalError {
  width: auto;
  position: absolute;
  z-index: 1000;
  top: 2em;
  left: 1em;
}
</style>
