import { createApp } from 'vue'

import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/js/index.esm'

//npm install @popperjs/core --save

/*var GS = {
    User: null,
    Endpoint: null,
    //Endpoint: window.location.href.includes("http://localhost") ? "https://debug-connect.misoft.de:1234" : "https://connect-api.misoft.de",
}*/
/*
if (window.location.href.includes("http://localhost")) {
    GS.Endpoint = "http://127.0.0.1:5000"
}
else {
    let splittedHref = window.location.href.split('/')
    splittedHref.pop()
    GS.Endpoint = splittedHref.join('/') + '/api'
}

const a = {
    data: function () {
      return {
        GS: GS
      }
    },
    computed: {
      User: function () {
        return GS.User;
      },
    },
    methods: {


    }
};
  

const app = createApp({
    extends: App,
    mixins: [a],
    components: {

    }
  })
*/
//app.mixin(GS);

const app = createApp(App)

app.use(require('vue-cookies'), { expires: '14d'})
app.mount('#app')
