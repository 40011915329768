<template>
  <div>
    <div class="card editcard" v-if="add">
      <div class="card-header">
        Hinzufügen
        <span class="text-danger" style="cursor: pointer;" @click="add = null"><i class="bi bi-x-lg float-end"
            style="font-weight:bold;"></i></span>
      </div>
      <div class="card-body">
        <div class="mb-3">
          <label for="add-item" class="form-label">Item</label>
          <select class="form-select" id="add-item" v-model="add.ItemId">
            <option v-for="(item, index) in $parent.items" v-bind:key="index" :value="item.id">{{ item.description }}
            </option>
          </select>
        </div>
        <div class="mb-3" v-if="addStage >= 1">
          <div class="btn-group" id="add-type">
            <a href="#" :class="'btn btn-success' + (add.Type != 0 ? ' opacity-50' : '')"
              @click="add.Type = 0">Eingelagert</a>
            <a href="#" :class="'btn btn-danger' + (add.Type != 1 ? ' opacity-50' : '')" @click="add.Type = 1">Ausgelagert</a>
            <a href="#" :class="'btn btn-primary' + (add.Type != 2 ? ' opacity-50' : '')" @click="add.Type = 2">Verschwunden</a>
          </div>
        </div>
        <div class="mb-3" v-if="addStage >= 2">
          <label for="add-amount" class="form-label">Menge</label>
          <input type="number" min="0" class="form-control" id="add-amount" v-model="add.Amount" />
        </div>
        <div class="mb-3" v-if="addStage >= 3">
          <label for="add-member" class="form-label">{{(add.Type == 0 ? 'Eingelagert von' : (add.Type == 1 ? 'Ausgelagert von' : 'Schwund festgestellt von'))}}</label>
          <select class="form-select" id="add-member" v-model="add.MemberId">
            <option v-for="(member, index) in memberWithStoragePermission" v-bind:key="index" :value="member.id">{{ member.name }}
            </option>
          </select>
        </div>
        <div class="mb-3" v-if="addStage >= 4 && add.Type != 2">
          <label for="add-for" class="form-label">{{(add.Type == 0 ? 'Eingelagert' : ('Ausgelagert'))}} für</label><br />
          <div class="btn-group" id="add-for">
            <a href="#" :class="'btn btn-primary' + (add.For == 0 ? '' : ' opacity-50')" @click="add.For = 0">{{ $parent.members.filter((member) => member.id == add.MemberId)[0].name }}</a>
            <a href="#" :class="'btn btn-success' + (add.For == 1 ? '' : ' opacity-50')" @click="add.For = 1">Mehrere</a>
            <a href="#" :class="'btn btn-warning' + (add.For == 2 ? '' : ' opacity-50')" @click="add.For = 2">Eine Person</a>
          </div>
        </div>
        <div class="mb-3" v-if="addStage >= 4 && add.For == 2">
          <select class="form-select" id="add-member-for" v-model="add.ForMemberId">
            <option v-for="(member, index) in $parent.members" v-bind:key="index" :value="member.id">{{ member.name }}
            </option>
          </select>
        </div>
        <div class="mb-3" v-if="addStage >= 4">
          <label for="add-comment" class="form-label">Kommentar</label>
          <input type="text" class="form-control" id="add-comment" v-model="add.Comment" />
        </div>
        <button v-if="(addStage >=4 && add.For != 2) || (addStage >=4 && add.For == 2 && add.ForMemberId != null)" class="btn btn-success btn-sm" @click="addStorage">Hinzufügen</button>
      </div>
    </div>
    <!--
    <div class="card editcard" v-if="edit">
      <div class="card-header">
        Bearbeite {{ edit.name }}
        <span class="text-danger" style="cursor: pointer;" @click="edit = null; update = null"><i class="bi bi-x-lg fw-bold float-end"></i></span>
      </div>
      <div class="card-body">
        
          <div class="mb-3">
            <label for="edit-item-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="edit-item-name" v-model="edit.description" />
          </div>
          <div class="mb-3">
            <label for="edit-item-tech-name" class="form-label">Technical Name</label>
            <input type="text" class="form-control" id="edit-item-tech-name" v-model="edit.technical_name" />
          </div>
          <button class="btn btn-success btn-sm" @click="saveItem">Speichern</button>
        
      </div>
    </div>-->
    <div style="text-align: center; margin-bottom: 1em;" v-if="edit == null && update == null && add == null">
      <button class="btn btn-sm btn-success"
        @click="add = { ItemId: null, Amount: 0, MemberId: null, Type: -1, For: 0, ForMemberId: 0, Comment: null }" v-if="$parent.user.member.user.writePermission">Hinzufügen</button>
        <button class="btn btn-sm btn-primary"
        @click="onlyLoss = !onlyLoss" v-if="$parent.user.member.user.writePermission" style="margin-left: 1em">{{ onlyLoss ? 'Alles anzeigen' : 'Nur Schwund anzeigen' }}</button>
    </div>
    
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Datum</th>
          <th>Item</th>
          <th>Menge</th>
          <th>Gelagert von</th>
          <th>Gelagert für</th>
          <th v-if="$parent.user.member.user.writePermission">Aktion</th>
        </tr>
      </thead>
      <tbody v-for="(storage, index) in listWithFilter" v-bind:key="index">
        <tr :style="storage.comment ? 'border-bottom: 1px dashed' : ''">
          <td>{{ $parent.format_date(storage.createdOn) }}</td>
          <td>{{ storage.item.description }}</td>
          <td>{{ $parent.Tausender(storage.amount) }}</td>
          <td v-if="storage.loss" colspan="2" align="center"><span class="badge text-bg-danger">VERSCHWUNDEN</span></td>
          <td v-if="!storage.loss">{{ storage.member.name }}</td>
          <td v-if="!storage.loss">{{ (storage.sourceMember == null ? 'mehrere' : storage.sourceMember.name) }}</td>
          <td v-if="$parent.user.member.user.writePermission" class="actiontd" :style="storage.readOnly ? 'cursor: not-allowed;' : ''">
            <button class="btn btn-sm btn-danger" :disabled="storage.readOnly"
              @click="deleteStorage(storage.id)">Löschen</button>
          </td>
        </tr>
        <tr v-if="storage.comment" style="border-top: 0px; font-size: 1em; font-style: italic;">
          <td colspan="6">{{ storage.comment }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>



export default {
  name: 'StorageList',
  data: function () {
    return {
      edit: null,
      update: null,
      add: null,
      onlyLoss: false,
    }
  },
  computed: {
    addStage: function () {
      
      if (this.add == null || this.add.ItemId == null) {
        return 0;
      }
      else {
        if (this.add.ItemId != 0) {
          if (this.add.Type >= 0) {
            if(this.add.Amount != 0)
            {
              if(this.add.MemberId)
              {
                return 4;
              }
              else
              {
                return 3;
              }
            }
            else
            {
              return 2;
            }
          }
          else
          {
            return 1;
          }
        }
        else {
          return 1;
        }
      }
    },
    memberWithStoragePermission()
    {
      return this.$parent.members.filter((member) => member.rankId >= 7);
    },
    listWithFilter: function() {
      if(this.onlyLoss)
      {
        return this.$parent.storage.filter((storage) => storage.loss == '1');
      }
      else
      {
        return this.$parent.storage;
      }
    } 
  },
  methods: {
    addStorage: function () {
      var self = this;
      fetch(this.$parent.Endpoint + '/addStorage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + self.$parent.user.token },
        body: JSON.stringify(this.add)
      })
        .then((response) => {
          if (response.ok) {
            self.add = null;
            self.$parent.refreshStorage();

          }
          else {
            throw new Error('Something went wrong');

          }
        })
    },
    deleteStorage: function (id) {
      var self = this;
      fetch(this.$parent.Endpoint + '/deleteStorage', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + self.$parent.user.token },
        body: JSON.stringify({ Id: id})
      })
        .then((response) => {
          if (response.ok) {
            self.add = null;
            self.$parent.refreshStorage();

          }
          else {
            throw new Error('Something went wrong');

          }
        })
    },
  }
}
</script>

<style scoped>
.actiontd {
  display: flex;
  justify-content: space-evenly;

}

td {
  vertical-align: middle;
}

.editcard {
  margin-bottom: 2em;
}

.editcard .card-header {
  font-weight: bold !important;
}

.editcard .card-body {
  text-align: left !important;
}


table, td, tr
{
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
  
}
table
{
  border-radius: 10px;
  width: auto;
  margin: auto;
  margin-bottom: 30px;
}
th
{
  background-color: rgba(0, 0, 0, 0.5) !important;
  background: inherit;
  color: white !important;
}
th, td 
{
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.actiontd button:not(:first-child)
{
  margin-left: 10px;
}

.card {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
}
.card input,
.card input:focus,
.card input:active,
.card select,
.card select:focus,
.card select:active,
.card option,
.card option:focus,
.card option:active
{
  padding: 10px 20px;
  border-color: transparent;
  outline: none;
  font-size: 15px;
  background: transparent;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
  text-shadow: 10px solid black;
  box-shadow:inset 0px 0px 30px 5px rgba(0,0,0,0.31);
}

.card-body button:last-child {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.card select,
.card input {
  color:white !important;
}
</style>
