<template>
  <div>
    <div class="card editcard" v-if="add">
      <div class="card-header">
        Einstellen
        <span class="text-danger" style="cursor: pointer;" @click="add = null"><i class="bi bi-x-lg float-end" style="font-weight:bold;"></i></span>
      </div>
      <div class="card-body">
        
          <div class="mb-3">
            <label for="add-member-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="add-member-name" v-model="add.name" />
          </div>
          <div class="mb-3">
            <label for="add-rank" class="form-label">Rang</label>
            <select class="form-select" id="add-rank" v-model="add.rankId">
              <option v-for="(rank, index) in $parent.ranks" v-bind:key="index" :value="rank.id">{{ rank.description }}
              </option>
            </select>
          </div>
          <button class="btn btn-success btn-sm" @click="addmember">Einstellen</button>
        
      </div>
    </div>
    <div class="card editcard" v-if="edit">
      <div class="card-header">
        Bearbeite {{ edit.name }}
        <span class="text-danger" style="cursor: pointer;" @click="edit = null; update = null"><i class="bi bi-x-lg fw-bold float-end"></i></span>
      </div>
      <div class="card-body">
        
          <div class="mb-3">
            <label for="edit-member-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="edit-member-name" v-model="edit.name" />
          </div>
          <div class="mb-3">
            <label for="edit-member-rank" class="form-label">Rang</label>

            <select class="form-select" id="edit-member-rank" v-model="edit.rankId">
              <option v-for="(rank, index) in $parent.ranks" v-bind:key="index" :selected="edit.rankId == rank.id"
                :value="rank.id">{{ rank.description }}</option>
            </select>
          </div>
          <button class="btn btn-success btn-sm" @click="savemember">Speichern</button>
        
      </div>
    </div>
    <div style="text-align: center; margin-bottom: 1em;" v-if="edit == null && update == null && add == null">
      <button v-if="$parent.user.member.user.writePermission" class="btn btn-sm btn-success" @click="add = { name: null, rankId: 2 }">Einstellen</button>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Mitglied</th>
          <th>Rang</th>
          <th>Status</th>
          <th v-if="$parent.user.member.user.writePermission">Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(member, index) in $parent.members" v-bind:key="index">
          <td :class="member.memberState.technical_name == 'bloodout' ? 'bg-danger' : ''">{{ member.name }}</td>
          <td :class="member.memberState.technical_name == 'bloodout' ? 'bg-danger' : ''">{{ member.rank.description }}
          </td>
          <td :class="(member.memberState.technical_name == 'bloodout' ? 'bg-danger' : '') + (member.memberState.technical_name == 'inactive' ? 'text-danger' : '')">{{
            member.memberState.description }}</td>
          <td v-if="$parent.user.member.user.writePermission" :class="'actiontd' + (member.memberState.technical_name == 'bloodout' ? ' bg-danger' : '')">
            <button class="btn btn-sm btn-warning" v-if="member.memberState.technical_name != 'bloodout'"
              @click="edit = Object.assign({}, member); add = null; $parent.top();">Bearbeiten</button>
            <button class="btn btn-sm btn-success" v-if="member.memberState.technical_name == 'inactive'"
              @click="memberActive(member)">Aktiv</button>
            <button class="btn btn-sm btn-primary" v-if="member.memberState.technical_name == 'active'"
              @click="memberInactive(member)">Inaktiv</button>
            <button class="btn btn-sm btn-danger" v-if="member.memberState.technical_name != 'bloodout'"
              @click="bloodout(member)">Bloodout</button>
            <button class="btn btn-sm btn-success" v-if="member.memberState.technical_name == 'bloodout'"
              @click="bloodin(member)">Einstellen</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'MemberList',
  data: function () {
    return {
      edit: null,
      update: null,
      add: null,
    }
  },
  methods: {
    savemember: function () {
      var self = this;
      fetch(this.$parent.Endpoint + '/SaveMember', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + self.$parent.user.token },
        body: JSON.stringify(this.edit == null ? this.update : this.edit)
      })
        .then((response) => {
          if (response.ok) {
            self.edit = null;
            self.update = null;
            self.$parent.refreshMembers();

          }
          else {
            throw new Error('Something went wrong');

          }
        })
    },
    addmember: function () {
      var self = this;
      fetch(this.$parent.Endpoint + '/AddMember', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + self.$parent.user.token },
        body: JSON.stringify(this.add)
      })
        .then((response) => {
          if (response.ok) {
            self.add = null;
            self.$parent.refreshMembers();

          }
          else {
            throw new Error('Something went wrong');

          }
        })
    },
    bloodin: function (member) {
      this.update = member;
      this.update.memberStateId = 1;
      this.savemember();
    },
    bloodout: function (member) {
      this.update = member;
      this.update.memberStateId = 3;
      this.savemember();
    },
    memberActive: function (member) {
      this.update = member;
      this.update.memberStateId = 1;
      this.savemember();
    },
    memberInactive: function (member) {
      this.update = member;
      this.update.memberStateId = 2;
      this.savemember();
    }
  }
}
</script>

<style scoped>
.actiontd {
  display: flex;
  justify-content: space-evenly;

}

td {
  vertical-align: middle;
}

.editcard {
  margin-bottom: 2em;
}

.editcard .card-header {
  font-weight: bold !important;
}

.editcard .card-body {
  text-align: left !important;
}

table, td, tr
{
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
  
}
table
{
  border-radius: 10px;
  width: auto;
  margin: auto;
  margin-bottom: 30px;
}
th
{
  background-color: rgba(0, 0, 0, 0.5) !important;
  background: inherit;
  color: white !important;
}
th, td 
{
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.actiontd button:not(:first-child)
{
  margin-left: 10px;
}

.card {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
}
.card input,
.card input:focus,
.card input:active,
.card select,
.card select:focus,
.card select:active,
.card option,
.card option:focus,
.card option:active
{
  padding: 10px 20px;
  border-color: transparent;
  outline: none;
  font-size: 15px;
  background: transparent;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
  text-shadow: 10px solid black;
  box-shadow:inset 0px 0px 30px 5px rgba(0,0,0,0.31);
}

.card button{
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

</style>
