<template>
  <div class="hello">
    <div class="card">
      <div class="card-body">
        <p style="text-align:center;font-size: 2em;">Lagerverwaltung</p>
        <p v-if="$parent.bankSum"><b>Kassenstand:</b> {{ $parent.Tausender($parent.bankSum[0]) }} $</p>
        <hr />
        <p><b>Lager:</b></p>
        <div v-if="$parent.storageSum">
          <span style="display: block;" v-for="(item, index) in $parent.storageSum" v-bind:key="index"><b>{{ item.item.description }}</b>: {{ $parent.Tausender(item.amount) }}</span>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeSite',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.card {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
}
.card input,
.card input:focus,
.card input:active,
.card select,
.card select:focus,
.card select:active,
.card option,
.card option:focus,
.card option:active
{
  padding: 10px 20px;
  border-color: transparent;
  outline: none;
  font-size: 15px;
  background: transparent;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: left !important;
  text-shadow: 10px solid black;
  box-shadow:inset 0px 0px 30px 5px rgba(0,0,0,0.31);
}

.card-body button:last-child {
  display: block;
}

.card-body
{
  text-align: left !important;
}

.card select,
.card input {
  color:white !important;
}
p{
  display: block;
}

</style>
