<template>
  <div>
    <div class="card editcard" v-if="add">
      <div class="card-header">
        Hinzufügen
        <span class="text-danger" style="cursor: pointer;" @click="add = null"><i class="bi bi-x-lg float-end" style="font-weight:bold;"></i></span>
      </div>
      <div class="card-body">
        
          <div class="mb-3">
            <label for="add-item-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="add-item-name" v-model="add.description" />
          </div>
          <div class="mb-3">
            <label for="add-item-tech-name" class="form-label">Technical Name</label>
            <input type="text" class="form-control" id="add-item-tech-name" v-model="add.technical_name" />
          </div>
          <button class="btn btn-success btn-sm" @click="addItem">Hinzufügen</button>
        
      </div>
    </div>
    <div class="card editcard" v-if="edit">
      <div class="card-header">
        Bearbeite {{ edit.name }}
        <span class="text-danger" style="cursor: pointer;" @click="edit = null; update = null"><i class="bi bi-x-lg fw-bold float-end"></i></span>
      </div>
      <div class="card-body">
        
          <div class="mb-3">
            <label for="edit-item-name" class="form-label">Name</label>
            <input type="text" class="form-control" id="edit-item-name" v-model="edit.description" />
          </div>
          <div class="mb-3">
            <label for="edit-item-tech-name" class="form-label">Technical Name</label>
            <input type="text" class="form-control" id="edit-item-tech-name" v-model="edit.technical_name" />
          </div>
          <button class="btn btn-success btn-sm" @click="saveItem">Speichern</button>
        
      </div>
    </div>
    <div style="text-align: center; margin-bottom: 1em;" v-if="edit == null && update == null && add == null">
      <button v-if="$parent.user.member.user.writePermission" class="btn btn-sm btn-success" @click="add = { name: null, technical_name: null }">Hinzufügen</button>
    </div>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Item</th>
          <th>Tech-Name</th>
          <th v-if="$parent.user.member.user.writePermission">Aktion</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in $parent.items" v-bind:key="index">
          <td>{{ item.description }}</td>
          <td>{{ item.technical_name }}</td>
          <td v-if="$parent.user.member.user.writePermission" class="actiontd">
            <button class="btn btn-sm btn-warning"
              @click="edit = Object.assign({}, item); add = null; $parent.top();">Bearbeiten</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'ItemList',
  data: function () {
    return {
      edit: null,
      update: null,
      add: null,
    }
  },
  methods: {
    saveItem: function () {
      var self = this;
      fetch(this.$parent.Endpoint + '/saveItem', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + self.$parent.user.token },
        body: JSON.stringify(this.edit == null ? this.update : this.edit)
      })
        .then((response) => {
          if (response.ok) {
            self.edit = null;
            self.update = null;
            self.$parent.refreshItems();

          }
          else {
            throw new Error('Something went wrong');

          }
        })
    },
    addItem: function () {
      var self = this;
      fetch(this.$parent.Endpoint + '/addItem', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + self.$parent.user.token },
        body: JSON.stringify(this.add)
      })
        .then((response) => {
          if (response.ok) {
            self.add = null;
            self.$parent.refreshItems();

          }
          else {
            throw new Error('Something went wrong');

          }
        })
    },
  }
}
</script>

<style scoped>
.actiontd {
  display: flex;
  justify-content: space-evenly;

}

td {
  vertical-align: middle;
}

.editcard {
  margin-bottom: 2em;
}

.editcard .card-header {
  font-weight: bold !important;
}

.editcard .card-body {
  text-align: left !important;
}
table, td, tr
{
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
  
}
table
{
  border-radius: 10px;
  width: auto;
  margin: auto;
  margin-bottom: 30px;
}
th
{
  background-color: rgba(0, 0, 0, 0.5) !important;
  background: inherit;
  color: white !important;
}
th, td 
{
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.actiontd button:not(:first-child)
{
  margin-left: 10px;
}

.card {
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background: inherit;
  color: white !important;
  text-shadow: 0px 0px 7px rgba(0,0,0,0.8);
}
.card input,
.card input:focus,
.card input:active,
.card select,
.card select:focus,
.card select:active,
.card option,
.card option:focus,
.card option:active
{
  padding: 10px 20px;
  border-color: transparent;
  outline: none;
  font-size: 15px;
  background: transparent;
  color: rgb(0, 0, 0);
  font-weight: bold;
  text-align: center;
  text-shadow: 10px solid black;
  box-shadow:inset 0px 0px 30px 5px rgba(0,0,0,0.31);
}

.card-body button:last-child {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
}

.card select,
.card input {
  color:white !important;
}
</style>
